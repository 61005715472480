import { useOSStore, useAuthenticationStore, RuntimeType } from '@/stores'
import { APP_ENVIRONMENT, FEATURE_WALLET_ENABLED } from '@/constant'
import { useMemo } from 'react'
import { solcloud, type SolcloudTypes } from '../solcloud'

type UserWithTokens = SolcloudTypes['UserWithTokens']

interface AuthenticationProvider<T = object> {
  authenticate: (options: T) => Promise<UserWithTokens | null>
  logout: () => void
}

export const useMockProvider = (): AuthenticationProvider<{ username: string }> => {
  const mockLoginMutation = solcloud.useMutation('mockLogin')

  return useMemo(
    () => ({
      authenticate: async ({ username }) => await mockLoginMutation.mutateAsync({ username }),
      logout: () => useAuthenticationStore.getState().setAuthToken(null)
    }),
    [mockLoginMutation]
  )
}

export const useTelegramWebProvider = (): AuthenticationProvider<{
  initData: string
  inviteKey: string | undefined
}> => {
  const telegramLoginMutation = solcloud.useMutation('telegramLogin')

  return useMemo(
    () => ({
      authenticate: async ({ initData, inviteKey }) => await telegramLoginMutation.mutateAsync({ initData, inviteKey }),
      logout: () => useAuthenticationStore.getState().setAuthToken(null)
    }),
    [telegramLoginMutation]
  )
}

export const useWeb3WalletProvider = (): AuthenticationProvider<null> => {
  return {
    authenticate: async () => {
      throw new Error('Not supported yet.')
    },
    logout: () => {
      throw new Error('Not supported yet.')
    }
  }

  // const { t } = useTranslation()
  // const walletLoginMutation = trpc.solanaWalletLogin.useMutation()

  // const wallet = useWallet()
  // const walletModal = useWalletModal()

  // // 标记认证流程开始
  // const authFlowStarted = useRef(false)
  // // 标记正在签名
  // const signing = useRef(false)

  // const walletSignMessage = useCallback(async (): Promise<UserWithTokens | null> => {
  //   if (!wallet.publicKey || !wallet.signMessage || signing.current) return null

  //   try {
  //     signing.current = true

  //     const signInPayload = {
  //       domain: window.location.host,
  //       publicKey: wallet.publicKey.toBase58(),
  //       statement: `Sign this message to sign in to TurbX.`,
  //       nonce: Math.random().toString(36).slice(2)
  //     }

  //     const message = new SigninMessage(signInPayload)

  //     const data = new TextEncoder().encode(message.prepare())
  //     const signature = await wallet.signMessage(data)

  //     const user = await walletLoginMutation.mutateAsync({
  //       ...signInPayload,
  //       signature: message.encode(signature)
  //     })
  //     useAuthenticationStore.getState().setAuthToken(user.tokens)
  //     signing.current = false
  //     authFlowStarted.current = false

  //     return user
  //   } catch (error) {
  //     signing.current = false
  //     authFlowStarted.current = false

  //     console.error(error)
  //     useNotificationStore.getState().alert(t(`common.authorization.wallet.failed.message`))
  //     captureException(error)
  //   }

  //   return null
  // }, [t, wallet, walletLoginMutation])

  // useEffect(() => {
  //   if (!authFlowStarted.current) return

  //   if (wallet.connected && !useAuthenticationStore.getState().authToken) {
  //     console.debug('connected')
  //     walletSignMessage()
  //   }
  // }, [walletSignMessage, wallet.connected])

  // return {
  //   authenticate: async (): Promise<UserWithTokens | null> => {
  //     // 每次点击就 disconnect 重新开始认证流程
  //     await wallet.disconnect()
  //     authFlowStarted.current = true
  //     // 通过弹窗让用户选择钱包
  //     walletModal.setVisible(true)

  //     return null
  //   },
  //   logout: (): void => {
  //     useAuthenticationStore.getState().setAuthToken(null)
  //     wallet.disconnect()
  //   }
  // }
}

const readRuntime = (): RuntimeType | 'mock' => {
  const isDev = import.meta.env.DEV || APP_ENVIRONMENT === 'staging'
  let runtime: RuntimeType | 'mock' = useOSStore.getState().runtime
  runtime = runtime === RuntimeType.Browser && isDev ? 'mock' : runtime

  return runtime
}

export const useUserAuthentication = (): {
  signOut: () => Promise<void>
  signIn: () => Promise<UserWithTokens | null>
} => {
  const web3WalletProvider = useWeb3WalletProvider()
  const mockProvider = useMockProvider()
  const telegramWebProvider = useTelegramWebProvider()

  const authenticateSucceed = (user: UserWithTokens | null): UserWithTokens | null => {
    if (!user) return null
    useAuthenticationStore.getState().setAuthToken(user.tokens)
    useAuthenticationStore.getState().setIsNew(user.user.isNew)

    return user
  }

  return {
    signOut: async (): Promise<void> => {
      const runtime = readRuntime()

      if (FEATURE_WALLET_ENABLED) {
        // 全局走钱包登录
        return web3WalletProvider.logout()
      } else {
        switch (runtime) {
          case 'mock':
            return mockProvider.logout()
          case RuntimeType.TMA:
            return telegramWebProvider.logout()
        }
      }
    },
    signIn: async (): Promise<UserWithTokens | null> => {
      const runtime = readRuntime()

      if (FEATURE_WALLET_ENABLED) {
        // 全局走钱包登录
        return authenticateSucceed(await web3WalletProvider.authenticate(null))
      } else {
        switch (runtime) {
          case 'mock':
            return authenticateSucceed(await mockProvider.authenticate({ username: 'admin' }))
          case RuntimeType.TMA:
            return authenticateSucceed(
              await telegramWebProvider.authenticate({
                initData: window.Telegram.WebApp.initData,
                inviteKey: window.Telegram.WebApp.initDataUnsafe.start_param
              })
            )
        }
      }

      throw new Error('Unknown Authentication')
    }
  }
}
