import { type FC } from 'react'
import { useModal, Modal } from '@/components'
import { Box, Divider, HStack, VStack } from '@/styled-system/jsx'
import { Link } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface ConnectWalletModalProps {}

export const connectWalletModalId = 'connect-wallet-modal'

export const ConnectWalletModal: FC<ConnectWalletModalProps> = () => {
  const { t } = useTranslation()
  const connectWalletModal = useModal(connectWalletModalId)

  return (
    <Modal modalId={connectWalletModal.modalId}>
      <VStack
        alignItems="flex-start"
        w="calc(100% - 35px * 2)"
        mx="auto"
        rounded="16px"
        bgColor="rgba(46, 47, 56, 0.90)"
        backdropFilter="blur(6px)"
        p="24px"
        gap="24px">
        <HStack gap="8px">
          <Divider w="2px" h="15px" color="#ADFF26" orientation="vertical" />
          <Box color="#FFFFFF" fontSize="16px" fontWeight={510}>
            {t(`common.connectWalletModal.heading`)}
          </Box>
        </HStack>
        <Box color="rgba(255, 255, 255, 0.4)" fontSize="16px" fontWeight={400}>
          {t(`common.connectWalletModal.message`)}
        </Box>
        <HStack gap="12px" w="100%">
          <HStack
            fontSize="14px"
            fontWeight={590}
            rounded="8px"
            bgColor="#575862"
            color="#FFFFFF"
            h="48px"
            justifyContent="center"
            flex="1"
            onClick={connectWalletModal.close}>
            {t(`common.connectWalletModal.cancelButton.label`)}
          </HStack>
          <HStack
            fontSize="14px"
            fontWeight={590}
            rounded="8px"
            bgColor="#ADFF26"
            color="#000000"
            h="48px"
            justifyContent="center"
            flex="1">
            <Link to="/wallet">{t(`common.connectWalletModal.confirmButton.label`)}</Link>
          </HStack>
        </HStack>
      </VStack>
    </Modal>
  )
}
