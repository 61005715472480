import { css } from '@/styled-system/css'
import { clsx } from '@turbx/deprecated-design-system'
import { motion } from 'framer-motion'
import type { FC, MouseEventHandler, PropsWithChildren } from 'react'

export interface BackdropProps {
  className?: string
  onClick?: MouseEventHandler<HTMLDivElement>
}

export const Backdrop: FC<PropsWithChildren<BackdropProps>> = ({ children, className, onClick }) => {
  return (
    <motion.div
      className={clsx(
        css({
          pos: 'fixed',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          bgColor: 'rgba(0, 0, 0, 0.40)',
          zIndex: 999
        }),
        className
      )}
      onClick={onClick}
      initial={{ opacity: 0, display: 'none' }}
      animate={{ opacity: 1, display: 'block' }}
      exit={{ opacity: 0, display: 'none' }}>
      {children}
    </motion.div>
  )
}
