import type { FC } from 'react'
import { Icon } from '@turbx/deprecated-design-system'
import { TokenAvatar } from './token-avatar.component'
import { intAbbr, numberAbbr } from '@/utils'
import { css, cx } from '@/styled-system/css'
import { getSymbolOfChain } from '@/utils/web3'
import type { SolcloudTypes } from '@/stores/solcloud'

import hotTokenBg from './assets/token/hot-token-bg.svg?inline'

const tokenInfo = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  h: '64px'
})

const tokenContent = css({
  marginLeft: '16px',
  display: 'flex',
  flexDirection: 'column',
  flex: 1
})

const tokenSymbol = css({
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '16.71px',
  color: '#000000',
  textAlign: 'left'
})

const pairSymbol = css({
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '16.71px',
  color: 'rgba(0, 0, 0, 0.30)',
  textAlign: 'left'
})

const tokenSocialScore = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '14.32px',
  color: '#0000004D',
  marginTop: '2px'
})

const tokenPriceContainer = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end'
})

const tokenPrice = css({
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '16px',
  color: '#000000'
})

const tokenPriceUnit = css({
  fontWeight: 500,
  color: '#00000066',
  marginRight: '2px'
})

const tokenIncrease = css({
  marginTop: '2px',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '16px',
  color: '#00A023'
})

const darkTokenIncrease = css({
  color: '#ADFF26'
})

const tokenDecrease = css({
  color: '#F12B00'
})

const darkTokenDecrease = css({
  color: '#FF4C41'
})

const holdAmount = css({
  color: '#72E3B4',
  fontSize: '16px',
  fontWeight: 400
})

const holdTotal = css({
  color: '#FFFFFF',
  fontSize: '14px',
  fontWeight: 400
})

const hotToken = css({
  backgroundImage: `url('${hotTokenBg}')`,
  backgroundPosition: 'left top',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat'
})

const chatIcon = css({
  height: '12px',
  width: '12px',
  marginInlineEnd: '2px'
})

const darkMainText = css({
  color: '#FFFFFF'
})

const darkText = css({
  color: 'rgba(255, 255, 255, 0.3)'
})

export interface TokenPanelProps {
  dark?: boolean
  className?: string
  enableHotEffect?: boolean
  community: SolcloudTypes['CommunityType']['community'] | null
  token: SolcloudTypes['TokenAccountType']['token']
  tokenAccount?: SolcloudTypes['TokenAccountType']['tokenAccount']
  priceItem: SolcloudTypes['PriceItem'] | undefined
}

export const TokenPanel: FC<TokenPanelProps> = ({
  className,
  enableHotEffect,
  token,
  tokenAccount,
  community,
  priceItem,
  dark
}) => {
  const isDark = !!dark
  const increaseClassNames = [tokenIncrease, isDark && darkTokenIncrease]
  const decreaseClassNames = [tokenIncrease, tokenDecrease, isDark && darkTokenDecrease]

  return (
    <div className={cx(tokenInfo, enableHotEffect && hotToken, className)}>
      <TokenAvatar avatar={token.logo ?? ''} heat={0} dark={isDark} />
      <div className={tokenContent}>
        <span className={cx(tokenSymbol, dark && darkMainText)}>
          {token.symbol}
          <span className={cx(pairSymbol, dark && darkText)}> / {getSymbolOfChain(token)}</span>
        </span>
        {community && (
          <div className={cx(tokenSocialScore, dark && darkText)}>
            <Icon name="chat" className={chatIcon} />
            {intAbbr(community.comment_message_count)}
          </div>
        )}
      </div>
      <div className={tokenPriceContainer}>
        {tokenAccount ? (
          <>
            <span className={cx(dark && holdAmount)}>{numberAbbr(Number(tokenAccount.balance))}</span>
            <span className={tokenPrice}>
              {priceItem ? (
                <>
                  <span className={cx(tokenPriceUnit, dark && darkText)}>$</span>
                  <span className={cx(dark && holdTotal)}>
                    {Number(Number(priceItem.priceUSD) * Number(tokenAccount.balance)).toFixed(6)}
                  </span>
                </>
              ) : (
                <span className={cx(dark && holdTotal)}>-&nbsp;</span>
              )}
            </span>
          </>
        ) : (
          <>
            <span className={tokenPrice}>
              {priceItem ? (
                <>
                  <span className={cx(tokenPriceUnit, dark && darkText)}>$</span>
                  <span className={cx(dark && darkMainText)}>{Number(priceItem.priceUSD).toFixed(6)}</span>
                </>
              ) : (
                <span className={cx(dark && darkMainText)}>-&nbsp;</span>
              )}
            </span>
            {priceItem?.priceChange24h ? (
              <span
                className={cx(...(Number(priceItem.priceChange24h) >= 0 ? increaseClassNames : decreaseClassNames))}>
                {Number(priceItem.priceChange24h).toFixed(2)}%
              </span>
            ) : (
              <span className={cx(...increaseClassNames)}>-&nbsp;</span>
            )}
          </>
        )}
      </div>
    </div>
  )
}
