import { motion } from 'framer-motion'
import { Backdrop } from './backdrop.component'
import { type FC, type PropsWithChildren } from 'react'
import { AnimatePresence } from '@turbx/deprecated-design-system'
import { useModalStore } from './modal.hook'
import { createPortal } from 'react-dom'
import { VStack } from '@/styled-system/jsx'
import { css } from '@/styled-system/css'

const dropIn = {
  hidden: {
    y: '-100vh',
    opacity: 0
  },
  visible: {
    y: '0',
    opacity: 1,
    transition: {
      duration: 0.1,
      type: 'spring',
      damping: 25,
      stiffness: 500
    }
  },
  exit: {
    y: '100vh',
    opacity: 0
  }
}

const popup = {
  hidden: {
    y: '100vh',
    opacity: 0
  },
  visible: {
    y: '0',
    opacity: 1,
    transition: {
      duration: 0.1
    }
  },
  exit: {
    y: '100vh',
    opacity: 0
  }
}

const animates = {
  dropIn,
  popup
}

export interface ModalProps {
  className?: string
  overlayClassName?: string
  modalId: string
  placement?: 'top' | 'center' | 'bottom'
  closeOnClickOverlay?: boolean
  animate?: 'dropIn' | 'popup'
}

export const Modal: FC<PropsWithChildren<ModalProps>> = ({
  className,
  overlayClassName,
  modalId,
  placement = 'center',
  closeOnClickOverlay = true,
  animate = 'dropIn',
  children
}) => {
  useModalStore((state) => state.config[modalId])
  const isVisible = useModalStore((state) => state.isVisible)
  const close = useModalStore((state) => state.close)

  return createPortal(
    <AnimatePresence
      // Disable any initial animations on children that
      // are present when the component is first rendered
      initial={false}
      // Only render one component at a time.
      // The exiting component will finish its exit
      // animation before entering component is rendered
      mode="wait"
      onExitComplete={() => null}>
      {isVisible(modalId) && (
        <Backdrop
          className={overlayClassName}
          onClick={() => {
            if (closeOnClickOverlay) close(modalId)
          }}>
          <VStack
            css={{
              w: '100%',
              h: '100%',
              py: '20%'
            }}
            className={className}
            justifyContent={placement === 'top' ? 'flex-start' : placement === 'bottom' ? 'flex-end' : 'center'}>
            <motion.div
              className={css({
                w: '100%'
              })}
              onClick={(e) => e.stopPropagation()}
              variants={animates[animate]}
              initial="hidden"
              animate="visible"
              exit="exit">
              {children}
            </motion.div>
          </VStack>
        </Backdrop>
      )}
    </AnimatePresence>,
    document.body
  )
}
