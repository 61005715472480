import { debounce } from '@turbx/utils'
import { useEffect } from 'react'
import { create } from 'zustand'

export enum RuntimeType {
  /** telegram mini app */
  'TMA',
  'Browser',
  'App'
}

export enum PlatformType {
  'Macos',
  'Windows',
  'Ios',
  'Android',
  'Unknown'
}

export interface OSStoreState {
  runtime: RuntimeType
  platform: PlatformType
  screenDimensions: {
    height: number
    width: number
  }
}

export const useOSStore = create<OSStoreState>(() => ({
  runtime: window.Telegram?.WebApp?.initData ? RuntimeType.TMA : RuntimeType.Browser,
  platform: PlatformType.Unknown,
  screenDimensions: {
    height: window.innerHeight,
    width: window.innerWidth
  }
}))

export function useWindowResize(): void {
  const runtime = useOSStore((state) => state.runtime)

  useEffect(() => {
    if (RuntimeType.Browser === runtime || RuntimeType.TMA === runtime) {
      const listener = debounce({ delay: 100 }, () =>
        useOSStore.setState({
          screenDimensions: {
            height: window.innerHeight,
            width: window.innerWidth
          }
        })
      )
      window.addEventListener('resize', listener)
      return (): void => window.removeEventListener('resize', listener)
    }
  }, [runtime])
}
