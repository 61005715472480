import { Outlet, type RouteComponent, createFileRoute } from '@tanstack/react-router'
import { Navbar } from '@/components'
import { Box, GridItem } from '@/styled-system/jsx'

const NavigableLayout: RouteComponent = () => {
  return (
    <Box
      css={{
        display: { base: 'block', md: 'grid' },
        gridTemplateColumns: { base: 'unset', md: 'auto 1fr' },
        minH: '100svh'
      }}>
      <Navbar />
      <GridItem
        css={{
          h: '100svh',
          overflow: 'auto'
        }}>
        <Outlet />
      </GridItem>
    </Box>
  )
}

export const Route = createFileRoute('/_authenticated/_navigable')({
  component: NavigableLayout
})
