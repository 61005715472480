import { Box, Container, HStack, VStack } from '@/styled-system/jsx'
import { createFileRoute, Link, type RouteComponent } from '@tanstack/react-router'
import { css } from '@/styled-system/css'
import { Icon, motion } from '@turbx/deprecated-design-system'
import { router } from '@/initializers'
import { useDemoGameStore } from './demo.store'
import { useState } from 'react'
import { Modal, useModal, useNotificationStore } from '@/components'

import bg2 from './assets/bg2.png'
import bg3 from './assets/bg3.png'
import treasureImage from './assets/treasure-open.png'
import contributeImage from './assets/contribute-button.png'
import decoratorImage from './assets/decorator.png'

const donateConfirmModalId = 'donateConfirm'

const TurbVault: RouteComponent = () => {
  const reward = useDemoGameStore((state) => state.reward)
  const [donatedValue, setDonatedValue] = useState<string>()

  const donateConfirmModal = useModal(donateConfirmModalId)

  return (
    <Container
      css={{
        h: '100svh',
        p: 0
      }}>
      <VStack
        css={{
          w: '100%',
          h: '100%',
          pos: 'relative',
          bgSize: '100%',
          bgRepeat: 'repeat-y',
          bgPosition: 'top',
          gap: 0
        }}
        style={{
          backgroundImage: `url('${bg2}')`
        }}>
        <img
          className={css({
            pos: 'absolute',
            top: '-150px',
            left: 0,
            w: '100%'
          })}
          src={bg3}
        />
        <HStack
          css={{
            pos: 'relative',
            zIndex: 1,
            w: '100%',
            p: '35px 28px',
            gap: '30px'
          }}>
          <VStack
            onClick={() => {
              if (router.history.location.state.key) {
                router.history.back()
              }
            }}
            css={{
              bgColor: 'rgba(66, 35, 0, 1)',
              w: '26px',
              h: '26px',
              rounded: '26px',
              border: '2px solid rgba(84, 49, 42, 1)'
            }}>
            <Icon
              name="arrow-left"
              className={css({
                w: '24px',
                h: '24px',
                color: '#FFF'
              })}
            />
          </VStack>
          <Box
            css={{
              color: 'rgba(22, 0, 0, 1)',
              fontFamily: 'Fredoka',
              fontSize: '24px',
              fontWeight: 700,
              lineHeight: '28.64px'
            }}>
            Donation Turb Vault
          </Box>
        </HStack>
        <VStack
          css={{
            marginTop: '30px',
            pos: 'relative',
            zIndex: 1
          }}>
          <Box
            css={{
              fontSize: '16px',
              fontWeight: 500,
              color: '#FFF',
              fontFamily: 'Fredoka'
            }}>
            Currently total
          </Box>
          <VStack
            css={{
              gap: 0
            }}>
            <Box
              css={{
                fontFamily: 'Fredoka',
                fontOpticalSizing: 'auto',
                fontWeight: 700,
                fontVariationSettings: `"wdth" 100`,
                color: 'rgba(66, 35, 0, 1)',
                fontSize: '30.39px',
                lineHeight: '32px'
              }}>
              {reward}
            </Box>
            <Box
              css={{
                fontFamily: 'Fredoka',
                fontOpticalSizing: 'auto',
                fontWeight: 700,
                fontVariationSettings: `"wdth" 100`,
                color: 'rgba(66, 35, 0, 1)',
                fontSize: '16px',
                lineHeight: '16px'
              }}>
              SOL
            </Box>
          </VStack>
        </VStack>
        <motion.img
          className={css({
            pos: 'relative',
            zIndex: 1,
            w: '90px',
            marginTop: '15px'
          })}
          src={treasureImage}
        />
        <VStack
          css={{
            pos: 'relative',
            m: '-16px 33px 0',
            zIndex: 2,
            gap: 0,
            w: 'calc(100% - 33px * 2)'
          }}>
          <HStack
            css={{
              w: '100%',
              h: '72px',
              rounded: '16px',
              bgColor: 'rgba(255, 231, 197, 1)',
              border: '3px solid rgba(59, 34, 38, 1)',
              p: '0 23px',
              justifyContent: 'space-between',
              marginBottom: '20px'
            }}>
            <Box
              css={{
                fontSize: '24px',
                fontWeight: 500,
                color: 'rgba(0, 0, 0, 1)',
                fontFamily: 'Fredoka'
              }}>
              Amount
            </Box>
            <input
              placeholder="0"
              value={donatedValue ?? ''}
              onChange={(e) => setDonatedValue(e.target.value)}
              className={css({
                fontSize: '32px',
                fontWeight: 500,
                _placeholder: {
                  color: 'rgba(0, 0, 0, 0.2)'
                },
                color: 'rgba(0, 0, 0, 1)',
                fontFamily: 'Fredoka',
                flex: 1,
                w: '100px',
                textAlign: 'right'
              })}
            />
          </HStack>
          <motion.img
            onClick={() => {
              if (!donatedValue || Number(donatedValue) <= 0) return
              donateConfirmModal.open()
            }}
            whileTap={{
              scale: 0.97
            }}
            src={contributeImage}
          />
          <Link
            to="/games/donate-list"
            className={css({
              marginTop: '28px',
              fontSize: '16px',
              fontWeight: 700,
              color: '#FFF',
              fontFamily: 'Fredoka',
              textDecoration: 'underline'
            })}>
            Donation List
          </Link>
        </VStack>
      </VStack>

      <Modal modalId={donateConfirmModalId}>
        <VStack
          css={{
            gap: '20px'
          }}>
          <VStack
            css={{
              w: '280px',
              p: '48px 30px',
              bgColor: 'rgba(255, 231, 197, 1)',
              border: '3px solid rgba(59, 34, 38, 1)',
              rounded: '14px',
              margin: '0 auto',
              gap: '45px',
              pos: 'relative'
            }}>
            <img
              className={css({
                pos: 'absolute',
                top: 0,
                right: 0,
                transform: 'rotate(180deg) translate(-12px, 2px)',
                w: '63px'
              })}
              src={decoratorImage}
            />
            <img
              className={css({
                pos: 'absolute',
                bottom: 0,
                left: 0,
                transform: 'translate(-50%, 2px)',
                w: '63px'
              })}
              src={decoratorImage}
            />
            <Box
              css={{
                fontFamily: 'Fredoka',
                fontWeight: 600,
                fontSize: '20px',
                color: 'rgba(55, 29, 0, 1)'
              }}>
              Please confirm you are going to add {donatedValue} SOL to the Community TURB Vault
            </Box>
            <HStack
              css={{
                w: '100%',
                gap: '23px'
              }}>
              <HStack
                css={{
                  bgColor: 'rgba(255, 255, 255, 1)',
                  h: '49px',
                  rounded: '9px',
                  border: '3px solid rgba(60, 18, 18, 1)',
                  flex: 1,
                  color: 'rgba(133, 98, 85, 1)',
                  fontSize: '24px',
                  lineHeight: '600',
                  fontFamily: 'Fredoka',
                  justifyContent: 'center'
                }}
                onClick={donateConfirmModal.close}>
                Cancel
              </HStack>
              <HStack
                css={{
                  bgColor: 'rgba(0, 0, 0, 1)',
                  h: '49px',
                  rounded: '9px',
                  border: '3px solid rgba(60, 18, 18, 1)',
                  flex: 1,
                  color: 'rgba(243, 255, 70, 1)',
                  fontSize: '24px',
                  lineHeight: '600',
                  fontFamily: 'Fredoka',
                  justifyContent: 'center'
                }}
                onClick={() => {
                  useDemoGameStore.setState({
                    reward: reward + Number(donatedValue)
                  })
                  useNotificationStore.getState().alert('Contribute successful')
                  setDonatedValue(undefined)
                  donateConfirmModal.close()
                }}>
                Confirm
              </HStack>
            </HStack>
          </VStack>
        </VStack>
      </Modal>
    </Container>
  )
}

export const Route = createFileRoute('/_authenticated/_navigable/games/turb-vault')({
  component: TurbVault
})
