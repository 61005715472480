import * as web3 from '@solana/web3.js'
import { type RpcAccount, type Umi } from '@metaplex-foundation/umi'
import { mplTokenMetadata, type DigitalAssetWithToken, type Metadata } from '@metaplex-foundation/mpl-token-metadata'
import { createUmi } from '@metaplex-foundation/umi-bundle-defaults'
import {
  type ExtendedLiquidityStateV4,
  type ExtendedPoolInfoV4,
  type ExtendedRawMint,
  type NFTMintPublicKeyType,
  type PoolNormalizedType,
  type TokenBaseType,
  type TokenAccountType
} from './interface'
import { fetchMultipleSingleTokenAccounts, fetchTokenAccount } from './nft'
import { MintLayout, TOKEN_2022_PROGRAM_ID, TOKEN_PROGRAM_ID, unpackMint } from '@solana/spl-token'
import { formatAmmKeysById, getMintsByPair, getProgramAccounts } from './pair'
import { isValidSolanaAddress } from './keypair'
import { mintState } from './token'
import { deserializeMetadata } from '@metaplex-foundation/mpl-token-metadata'
import { Api, API_URLS, Raydium, SOL_INFO, type TokenInfo } from '@raydium-io/raydium-sdk-v2'
import {
  type SolanaAddress,
  type SolanaTokenPair,
  type SolanaTokenMint,
  type Loggers,
  type SpecialString,
  SpecialSolMints,
  SpecialSolAddresses
} from '@turbx/common'
import { createRaydiumAxiosInstance } from './axios'

type OnChainResult = PoolNormalizedType
export type SearchOnChainResult =
  | { type: 'invalid'; result: null }
  | { type: 'mint'; result: OnChainResult }
  | { type: 'mint'; result: null; mint: SolanaTokenMint }
  | { type: 'pair'; result: OnChainResult }
  | { type: 'error'; result: null; reason: string }

type MintAccountInfoType = {
  decimals: number
  program_id: SolanaAddress
  source: null | { type: 'pumpfun' | 'moonshot'; buffer?: Buffer }
}

export class SolanaBaseClient {
  public readonly connection: web3.Connection
  private readonly umi: Umi
  private readonly earlyAccessMintPublicKey: NFTMintPublicKeyType
  private readonly logger: Loggers
  public readonly raydium: Raydium

  constructor(options: {
    logger: Loggers
    connection: web3.Connection
    earlyAccessMintPublicKey: NFTMintPublicKeyType
  }) {
    this.logger = options.logger
    this.connection = options.connection
    this.earlyAccessMintPublicKey = options.earlyAccessMintPublicKey
    this.umi = createUmi(this.connection.rpcEndpoint)
    this.umi.use(mplTokenMetadata())

    const apiRequestTimeout = 10 * 1000

    const api = new Api({
      cluster: 'mainnet',
      timeout: apiRequestTimeout,
      urlConfigs: undefined,
      logCount: undefined,
      logRequests: true
    })

    // https://github.com/raydium-io/raydium-sdk-V2/blob/master/src/api/api.ts#L64
    api.api = createRaydiumAxiosInstance({
      baseURL: api.urlConfigs.BASE_HOST ?? API_URLS.BASE_HOST,
      timeout: apiRequestTimeout
      // adapter: fetchAdapter
    })

    // https://github.com/raydium-io/raydium-sdk-V2-demo/blob/master/src/config.ts.template#L16-L26
    // https://github.com/raydium-io/raydium-sdk-V2/blob/master/src/raydium/raydium.ts#L154
    this.raydium = new Raydium({
      cluster: 'mainnet',
      connection: this.connection,
      disableFeatureCheck: true,
      disableLoadToken: true,
      blockhashCommitment: 'confirmed',
      apiRequestInterval: 5 * 60 * 1000,
      apiRequestTimeout,
      api
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any)
  }

  // https://github.com/raydium-io/raydium-sdk-V2/blob/master/src/raydium/token/token.ts#L27
  async activeRaydiumTokens(): Promise<TokenBaseType[]> {
    console.log('debug raidum 1')
    const [{ mintList, blacklist }, jup] = await Promise.all([
      this.raydium.api.getTokenList(),
      this.raydium.api.getJupTokenList()
    ])
    console.log('debug raidum 2')

    const _tokenMap = new Map()
    const _blackTokenMap = new Map()
    const _mintGroup = { official: new Set(), jup: new Set(), extra: new Set() }

    const _extraTokenList: TokenInfo[] = []

    _tokenMap.set(SOL_INFO.address, SOL_INFO)
    _mintGroup.official.add(SOL_INFO.address)

    blacklist.forEach((token) => {
      _blackTokenMap.set(token.address, { ...token, priority: -1 })
    })

    mintList.forEach((token) => {
      if (_blackTokenMap.has(token.address)) return
      _tokenMap.set(token.address, {
        ...token,
        type: 'raydium',
        priority: 2,
        programId:
          token.programId ??
          (token.tags.includes('token-2022') ? TOKEN_2022_PROGRAM_ID.toBase58() : TOKEN_PROGRAM_ID.toBase58())
      })
      _mintGroup.official.add(token.address)
    })

    jup.forEach((token) => {
      if (_blackTokenMap.has(token.address) || _tokenMap.has(token.address)) return
      _tokenMap.set(token.address, {
        ...token,
        type: 'jupiter',
        priority: 1,
        programId:
          token.programId ??
          (token.tags.includes('token-2022') ? TOKEN_2022_PROGRAM_ID.toBase58() : TOKEN_PROGRAM_ID.toBase58())
      })
      _mintGroup.jup.add(token.address)
    })

    _extraTokenList.forEach((token) => {
      if (_blackTokenMap.has(token.address) || _tokenMap.has(token.address)) return
      _tokenMap.set(token.address, {
        ...token,
        type: 'extra',
        priority: 1,
        programId:
          token.programId || token.tags.includes('token-2022')
            ? TOKEN_2022_PROGRAM_ID.toBase58()
            : TOKEN_PROGRAM_ID.toBase58()
      })
      _mintGroup.extra.add(token.address)
    })

    const tokenLists: TokenInfo[] = Array.from(_tokenMap).map((data) => data[1])
    return tokenLists.map((t) => ({
      mint: t.address as SolanaTokenMint,
      name: t.name,
      symbol: t.symbol,
      decimals: t.decimals,
      logo: { original: t.logoURI as SpecialString['COMMON']['image_url'] },
      program_id: t.programId as SolanaAddress,
      source: undefined,
      has_jup_router: undefined
    }))
  }

  async getSignatureStatus(transaction_id: string) {
    return await this.connection.getSignatureStatus(transaction_id, { searchTransactionHistory: true })
  }

  async getParsedTransaction(transaction_id: string) {
    return await this.connection.getParsedTransaction(transaction_id, {
      commitment: 'confirmed',
      maxSupportedTransactionVersion: 0
    })
  }

  async confirmTransaction(transaction_id: string) {
    // https://github.com/warp-id/solana-trading-bot/blob/master/transactions/default-transaction-executor.ts#L38
    const recentBlockhashForSwap = await this.connection.getLatestBlockhash()

    console.debug('recentBlockhashForSwap', recentBlockhashForSwap)

    return await this.connection.confirmTransaction(
      {
        signature: transaction_id,
        blockhash: recentBlockhashForSwap.blockhash,
        lastValidBlockHeight: recentBlockhashForSwap.lastValidBlockHeight
      },
      this.connection.commitment
    )
  }

  async fetchEarlyAccessTokenAccount(owner: SolanaAddress): Promise<DigitalAssetWithToken | null> {
    const asset = await fetchTokenAccount(this.umi, this.earlyAccessMintPublicKey, owner)
    this.logger.info('fetch early access token account', { asset, owner, mint: this.earlyAccessMintPublicKey })
    return asset
  }

  async fetchMultipleEarlyAccessTokenAccounts(owners: SolanaAddress[]): Promise<DigitalAssetWithToken[]> {
    const assets = await fetchMultipleSingleTokenAccounts(this.umi, this.earlyAccessMintPublicKey, owners)
    this.logger.info('fetch mutiple early access token accounts', {
      assets,
      owners,
      mint: this.earlyAccessMintPublicKey
    })
    return assets
  }

  async getBalance(owner: SolanaAddress): Promise<number> {
    const balance = await this.connection.getBalance(new web3.PublicKey(owner))
    this.logger.info('get balance', { balance, owner })
    return balance
  }

  // https://solana.stackexchange.com/a/7348
  async getMultipleBalances(addresses: SolanaAddress[]) {
    const accountsInfo = await this.connection.getMultipleAccountsInfo(addresses.map((a) => new web3.PublicKey(a)))

    const result = accountsInfo.reduce(
      (acc, a, idx) => {
        const address = addresses[idx]!
        acc[address] = a ? a.lamports / web3.LAMPORTS_PER_SOL : null
        return acc
      },
      {} as Record<SolanaAddress, number | null>
    )

    this.logger.info('get multiple balance', { addresses, accountsInfo, result })

    return result
  }

  // https://github.com/mirror520/first-web3/blob/main/src/app/solana.service.ts#L98
  // https://www.quicknode.com/guides/solana-development/spl-tokens/how-to-get-all-tokens-held-by-a-wallet-in-solana
  private renderTokenAccount(account: {
    pubkey: web3.PublicKey
    account: web3.AccountInfo<web3.ParsedAccountData>
  }): TokenAccountType {
    // https://github.com/solana-labs/solana-web3.js/blob/master/packages/rpc-parsed-types/src/token-accounts.ts#L8-L19
    const info = account.account.data.parsed.info
    const tokenAmount = info.tokenAmount as web3.TokenAmount
    return {
      owner: account.account.owner.toBase58() as SolanaAddress,
      pubkey: account.pubkey.toBase58() as SolanaAddress,
      balance: tokenAmount.uiAmountString!,
      decimals: tokenAmount.decimals,
      mint: info.mint as SolanaTokenMint
    }
  }

  async getTokenAccounts(owner: SolanaAddress): Promise<TokenAccountType[]> {
    const owner1 = new web3.PublicKey(owner)
    const [atsOld, atsNew] = await Promise.all([
      await this.connection.getParsedTokenAccountsByOwner(owner1, { programId: TOKEN_PROGRAM_ID }),
      await this.connection.getParsedTokenAccountsByOwner(owner1, { programId: TOKEN_2022_PROGRAM_ID })
    ])
    const result0 = [...atsOld.value, ...atsNew.value]

    const result1 = result0.map((r) => this.renderTokenAccount(r))
    this.logger.trace('get token accounts', { result0, result1, owner, atsOld, atsNew })
    return result1
  }

  async tokensMetadata(mints: SolanaTokenMint[]): Promise<Metadata[]> {
    if (mints.length === 0) return []
    const metadataPDAs = mints.map((mint) => {
      const [metadataPDA] = web3.PublicKey.findProgramAddressSync(
        [
          Buffer.from('metadata'),
          new web3.PublicKey(SpecialSolAddresses.TOKEN_METADATA).toBuffer(),
          new web3.PublicKey(mint).toBuffer()
        ],
        new web3.PublicKey(SpecialSolAddresses.TOKEN_METADATA)
      )
      return metadataPDA
    })

    const accounts = await this.connection.getMultipleAccountsInfo(metadataPDAs)

    return accounts.filter(Boolean).map((a) => deserializeMetadata(a as unknown as RpcAccount))
  }

  // async tokensMetadata(mints0: SolanaTokenMint[]): Promise<FindNftsByMintListOutput> {
  //   const mints = [...new Set(mints0)]
  //   if (mints.length === 0) return []

  //   try {
  //     const metaplex = Metaplex.make(this.connection)
  //     const infos = await metaplex.nfts().findAllByMintList({ mints: mints.map(mint => new web3.PublicKey(mint)) })

  //     console.log({ inputSize: mints.length, outputSize: infos.length }, 'fetch metaplex pair list done')

  //     return infos
  //   } catch (e) {
  //     console.error({ mints, err: e, error: (e as Error).message }, 'Failed to fetch metaplex pair list')
  //     return []
  //   }
  // }

  renderPool(pool: ExtendedLiquidityStateV4): PoolNormalizedType {
    const baseMint = pool.baseMint.toBase58() as SolanaTokenMint
    const quoteMint = pool.quoteMint.toBase58() as SolanaTokenMint

    if (baseMint === SpecialSolMints.WSOL) {
      return {
        pair: pool.pair,
        base_mint: quoteMint,
        quote_mint: baseMint,
        is_reversed: true
      }
    } else if (quoteMint === SpecialSolMints.WSOL) {
      return {
        pair: pool.pair,
        base_mint: baseMint,
        quote_mint: quoteMint,
        is_reversed: false
      }
    }

    throw new Error(`Invalid pool ${pool.pair}`)
  }

  async pairStateByMint(mint: SolanaTokenMint): Promise<ExtendedLiquidityStateV4 | null> {
    return await getProgramAccounts(this.connection, mint, SpecialSolMints.WSOL)
  }

  async findMintByPair(pair: SolanaTokenPair): Promise<SolanaTokenMint> {
    const state = await this.pairStateByPair(pair)
    if (!state) throw new Error(`pool ${pair} not found`)

    return this.renderPool(state).base_mint
  }

  async pairStateByPair(pair: SolanaTokenPair): Promise<ExtendedLiquidityStateV4> {
    return await getMintsByPair(this.connection, pair)
  }

  async poolInfoVerbose(pair: SolanaTokenPair): Promise<ExtendedPoolInfoV4> {
    return await formatAmmKeysById(this.connection, pair)
  }

  async mintStateVerbose(mint: SolanaTokenMint): Promise<ExtendedRawMint> {
    return await mintState(this.connection, mint)
  }

  async searchOnchain(query: string): Promise<SearchOnChainResult> {
    if (!isValidSolanaAddress<SolanaTokenPair | SolanaTokenMint>(query)) {
      return { type: 'invalid', result: null }
    }

    const isMint = web3.PublicKey.isOnCurve(new web3.PublicKey(query))

    try {
      if (isMint) {
        const result = await this.pairStateByMint(query as SolanaTokenMint)
        if (result) return { type: 'mint', result: this.renderPool(result) }
        return { type: 'mint', result, mint: query as SolanaTokenMint }
      }

      const result = await this.pairStateByPair(query as SolanaTokenPair)
      return { type: 'pair', result: this.renderPool(result) }
    } catch (e) {
      this.logger.error('search onchain error', { query }, e as Error)
      return { type: 'error', result: null, reason: (e as Error).message }
    }
  }

  async getAccount(mint: string | web3.PublicKey) {
    return await this.connection.getAccountInfo(new web3.PublicKey(mint))
  }

  private fallbackAccountInfo(mint: SolanaTokenMint, data: Pick<MintAccountInfoType, 'decimals' | 'program_id'>) {
    const name = mint.substring(0, 6)
    return { mint, name, symbol: name, logo: {}, tags: [], extensions: {}, ...data }
  }

  async getAccountInfos(mints: SolanaTokenMint[]) {
    const infos = await this.connection.getMultipleAccountsInfo(mints.map((mint) => new web3.PublicKey(mint)))

    // https://github.com/raydium-io/raydium-sdk-V2/blob/master/src/raydium/token/token.ts#L122C11-L122C15
    return infos
      .map((info, idx) => {
        if (!info) return null

        const mintInfo = unpackMint(new web3.PublicKey(mints[idx]!), info, info.owner)

        return {
          ...this.fallbackAccountInfo(mints[idx]!, {
            program_id: info.owner.toBase58() as SolanaAddress,
            decimals: mintInfo.decimals
          }),
          supply: mintInfo.supply.toString()
        }
      })
      .filter((t) => !!t)
  }

  async safeRaydiumTokenInfo(mint: SolanaTokenMint, local?: Pick<MintAccountInfoType, 'decimals' | 'program_id'>) {
    try {
      const token = await this.raydium.token.getTokenInfo(mint)

      return {
        mint: token.address as SolanaTokenMint,
        name: token.name,
        symbol: token.symbol,
        logo: { original: token.logoURI as SpecialString['COMMON']['image_url'] },
        decimals: token.decimals,
        tags: token.tags,
        extensions: token.extensions,
        program_id: token.programId as SolanaAddress
      }
    } catch (e) {
      this.logger.error('safeRaydiumTokenInfo error', { mint }, e as Error)

      if (local) return this.fallbackAccountInfo(mint, local)

      const info = (await this.getAccountInfos([mint]))[0]

      if (!info) throw e

      return info
    }
  }

  async mintAccountInfo(mint: SolanaTokenMint): Promise<MintAccountInfoType> {
    // https://github.com/rckprtr/pumpdotfun-sdk/blob/main/src/pumpfun.ts#L390
    const pumpfunCurveAddress = web3.PublicKey.findProgramAddressSync(
      [Buffer.from('bonding-curve'), new web3.PublicKey(mint).toBytes()],
      new web3.PublicKey(SpecialSolAddresses.PUMPFUN)
    )[0]
    // https://github.com/wen-moon-ser/moonshot-sdk/blob/main/src/solana/utils/getCurveAccount.ts#L13
    const moontshotCurveAddress = web3.PublicKey.findProgramAddressSync(
      [Buffer.from('token'), new web3.PublicKey(mint).toBytes()],
      new web3.PublicKey(SpecialSolAddresses.MOONSHOT)
    )[0]

    // // https://github.com/wen-moon-ser/moonshot-sdk/blob/main/src/solana/utils/getCurvePosition.ts#L17
    // const moonshotCurvePositionAddress = web3.PublicKey.findProgramAddressSync(
    //   [moontshotCurveAddress.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), new web3.PublicKey(mint).toBuffer()],
    //   ASSOCIATED_TOKEN_PROGRAM_ID
    // )[0]

    const [token, pumpfun, moonshot] = await this.connection.getMultipleAccountsInfo([
      new web3.PublicKey(mint),
      pumpfunCurveAddress,
      moontshotCurveAddress
    ])

    this.logger.debug(`account info predicate`, {
      mint,
      pumpfunCurveAddress,
      moontshotCurveAddress,
      token,
      pumpfun,
      moonshot
    })

    if (!token) throw new Error(`mint ${mint} not found`)

    const data = MintLayout.decode(token.data)
    this.logger.debug(`mint layout`, { mint, data })

    const common = {
      decimals: data.decimals,
      program_id: token.owner.toBase58() as SolanaAddress
    }

    if (pumpfun) {
      return { ...common, source: { type: 'pumpfun', buffer: pumpfun.data } }
    }

    if (moonshot) {
      return { ...common, source: { type: 'moonshot', buffer: moonshot.data } }
    }

    // check if finalized moonshot
    const txs = await this.connection.getSignaturesForAddress(moontshotCurveAddress, { limit: 1 }, 'confirmed')
    if (txs.length > 0) return { ...common, source: { type: 'moonshot', buffer: undefined } }

    return { ...common, source: null }
  }
}
