import type { FC } from 'react'
import BoringAvatar, { type AvatarProps as BoringAvatarProps } from 'boring-avatars'
import { clsx } from '../index'
import { avatar } from './avatar.css'

export interface AvatarProps {
  className?: string
  size: number
  name: string
  square?: boolean
  variant?: BoringAvatarProps['variant']
}

export const Avatar: FC<AvatarProps> = ({ name, size, className, square = true, variant = 'beam' }) => {
  return (
    <span className={clsx(avatar, className)}>
      <BoringAvatar
        square={square}
        name={name}
        size={size}
        variant={variant}
        colors={['#0A0310', '#3247C4', '#FF8599', '#FF7D10', '#FFB238']}
      />
    </span>
  )
}
