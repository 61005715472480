import { z } from 'zod'

// https://zod.dev/?id=transform
export const SlippageString = z
  .string()
  .refine(
    (s) => Number(s).toString() === s && Number(s) >= 0 && Number(s) <= 100,
    (_) => ({ message: `Invalid slippage` })
  )
  .transform((s) => Number(Number(s).toFixed(2)))
