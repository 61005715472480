import { createFileRoute, Outlet } from '@tanstack/react-router'
import { CommunityList } from './_index/components/community-list.component'
import { Grid, GridItem } from '@/styled-system/jsx'

const maxListPanelWidth = '340px'

export const Route = createFileRoute('/_authenticated/_navigable/_index')({
  component: () => (
    <Grid
      css={{
        display: 'block',
        gridGap: 0,
        md: {
          display: 'grid',
          gridTemplateColumns: `minmax(auto, ${maxListPanelWidth}) 1fr`
        }
      }}>
      <GridItem hideBelow="md">
        <CommunityList />
      </GridItem>
      <GridItem
        css={{
          md: {
            bgColor: 'rgba(242, 242, 242, 1)'
          }
        }}>
        <Outlet />
      </GridItem>
    </Grid>
  )
})
