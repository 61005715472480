import { css, type Styles } from '@/styled-system/css'
import { HStack, Box, VStack, Divider } from '@/styled-system/jsx'
import { createDataset } from '@/utils'
import { Icon } from '@turbx/deprecated-design-system'
import { useCallback, useState, type FC } from 'react'
import { useTranslation } from 'react-i18next'

export interface Filters {
  exchange: Array<'pumpfun' | 'moonshot'>
  index: Array<'S+' | 'S' | 'A+' | 'A' | 'B'>
}

export interface TopBarProps {
  searchEnabled?: boolean
  onSearchModeChange: (searchEnabled: boolean) => void
  onSearchQueryChange: (searchQuery: string) => void
  searchQuery: string
  filters: Filters
  setFilter: (type: 'exchange' | 'index', value: string) => void
}

const filterButtonCss: Styles = {
  h: '40px',
  bgColor: 'rgba(255, 255, 255, 1)',
  rounded: '51px',
  p: '0 16px',
  fontSize: '12px',
  fontWeight: 510,
  color: 'rgba(0, 0, 0, 0.6)',
  gap: '4px',
  border: '1px solid rgba(0, 0, 0, 0.1)',
  _active: {
    border: '1px solid rgba(129, 22, 195, 1)'
  },
  md: {
    border: 'none'
  }
}

type FilterType = 'exchange' | 'index'

const filterOptions = {
  exchange: [
    {
      value: 'pumpfun',
      label: 'Pump'
    },
    {
      value: 'moonshot',
      label: 'Moon'
    }
  ],
  index: [
    {
      value: 'S+',
      label: 'S+'
    },
    {
      value: 'S',
      label: 'S'
    },
    {
      value: 'A+',
      label: 'A+'
    },
    {
      value: 'A',
      label: 'A'
    },
    {
      value: 'B',
      label: 'B'
    }
  ]
} as const

export const topBarHeight = '100px'

export const TopBar: FC<TopBarProps> = ({
  searchEnabled,
  searchQuery,
  onSearchModeChange,
  onSearchQueryChange,
  filters,
  setFilter
}) => {
  const { t } = useTranslation()

  const [activeFilter, setActiveFilter] = useState<FilterType | null>(null)
  const toggleFilter = useCallback(
    (filterType: FilterType) => () => {
      if (filterType === activeFilter) setActiveFilter(null)
      else setActiveFilter(filterType)
    },
    [activeFilter]
  )

  return (
    <VStack
      css={{
        w: '100%',
        pos: 'absolute',
        top: 0,
        left: 0,
        zIndex: 10,
        h: activeFilter ? '100%' : 'unset',
        gap: 0
      }}>
      <VStack
        css={{
          minH: topBarHeight,
          paddingTop: '12px',
          w: '100%',
          bgColor: 'rgba(242, 242, 242, 1)',
          md: {
            bgColor: '#FFF'
          }
        }}>
        <HStack
          css={{
            w: '100%',
            gap: '16px',
            p: '0 16px'
          }}>
          <HStack
            {...createDataset({
              'data-active': searchEnabled
            })}
            css={{
              border: '1px solid rgba(0, 0, 0, 0.1)',
              bgColor: '#FFF',
              p: '11.5px 16px',
              justifyContent: 'center',
              w: '100%',
              rounded: '55px',
              _active: {
                justifyContent: 'flex-start'
              },
              md: {
                bgColor: 'rgba(242, 242, 242, 1)',
                border: 'none'
              }
            }}>
            <Icon
              name="search"
              className={css({
                color: 'rgba(0, 0, 0, 0.6)',
                w: '16px',
                h: '16px'
              })}
            />
            <input
              onClick={() => onSearchModeChange(true)}
              onChange={(e) => onSearchQueryChange(e.target.value)}
              value={searchQuery}
              className={css({
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '16.71px',
                flex: 1,
                _placeholder: {
                  color: 'rgba(0, 0, 0, 0.6)'
                }
              })}
              placeholder={t('common.searchMode.searchingText')}
            />
          </HStack>
          {searchEnabled && (
            <Box
              css={{
                fontWeight: 500,
                fontSize: '14px'
              }}
              onClick={() => {
                onSearchQueryChange('')
                onSearchModeChange(false)
              }}>
              {t('common.searchMode.cancelButton.label')}
            </Box>
          )}
        </HStack>
        {!searchEnabled && (
          <VStack
            css={{
              w: '100%',
              p: '0 16px'
            }}>
            <HStack
              css={{
                w: '100%'
              }}>
              <HStack
                css={{
                  gap: '8px',
                  flex: 1
                }}>
                <HStack
                  {...createDataset({
                    'data-active': filters.exchange.length > 0
                  })}
                  css={filterButtonCss}
                  onClick={toggleFilter('exchange')}>
                  Exchange
                  <Icon
                    name="arrow-down"
                    className={css({
                      color: 'rgba(0, 0, 0, 0.3)',
                      w: '16px',
                      h: '16px'
                    })}
                    style={{
                      transform: activeFilter === 'exchange' ? 'rotate(180deg)' : 'unset'
                    }}
                  />
                </HStack>
                <HStack
                  {...createDataset({
                    'data-active': filters.index.length > 0
                  })}
                  css={filterButtonCss}
                  onClick={toggleFilter('index')}>
                  Index
                  <Icon
                    name="arrow-down"
                    className={css({
                      color: 'rgba(0, 0, 0, 0.3)',
                      w: '16px',
                      h: '16px'
                    })}
                    style={{
                      transform: activeFilter === 'index' ? 'rotate(180deg)' : 'unset'
                    }}
                  />
                </HStack>
              </HStack>
              {/* <HStack css={filterButtonCss}>Trading Volume</HStack> */}
            </HStack>
            {activeFilter && (
              <>
                <Divider color="rgba(0, 0, 0, 0.1)" />
                <HStack css={{ w: '100%', flexWrap: 'wrap', maxW: '100%', paddingBottom: '12px' }}>
                  {filterOptions[activeFilter].map((i) => (
                    <HStack
                      css={{
                        border: '1px solid rgba(0, 0, 0, 0.1)',
                        bgColor: '#FFF',
                        h: '48px',
                        p: '0 16px',
                        rounded: '51px',
                        color: 'rgba(0, 0, 0, 0.6)',
                        fontSize: '14px',
                        fontWeight: 510,
                        gap: '4px'
                      }}
                      key={i.value}
                      onClick={() => setFilter(activeFilter, i.value)}>
                      <Icon
                        name={
                          filters[activeFilter].find((option) => option === i.value)
                            ? 'checkbox-fill'
                            : 'checkbox-blank-line'
                        }
                        style={{
                          color: filters[activeFilter].find((option) => option === i.value)
                            ? 'rgba(129, 22, 195, 1)'
                            : 'rgba(0, 0, 0, 0.3)'
                        }}
                        className={css({
                          w: '24px',
                          h: '24px'
                        })}
                      />
                      {i.label}
                    </HStack>
                  ))}
                </HStack>
              </>
            )}
          </VStack>
        )}
      </VStack>
      {!searchEnabled && activeFilter && (
        <Box
          onClick={() => setActiveFilter(null)}
          css={{
            bgColor: 'rgba(0, 0, 0, 0.4)',
            flex: 1,
            w: '100%'
          }}
        />
      )}
    </VStack>
  )
}
