import { css } from '@/styled-system/css'
import { Avatar, clsx } from '@turbx/deprecated-design-system'
import type { FC } from 'react'

export interface UserAvatarProps {
  size: number
  src?: string
  address: string
  className?: string
}

export const UserAvatar: FC<UserAvatarProps> = ({ size, src, address, className }) => {
  return src ? (
    <img
      className={clsx(
        css({
          w: `${size}px`,
          h: `${size}px`
        }),
        className
      )}
      src={src}
    />
  ) : (
    <Avatar square={false} size={size} name={address} className={className} />
  )
}
