import { useModal, PriceIncrease, footerHeight, Modal } from '@/components'
import { useTokenAccounts } from '@/hooks'
import { router } from '@/initializers'
import { solcloud, useAuthenticationStore, type SolcloudTypes } from '@/stores'
import { css } from '@/styled-system/css'
import { Container, VStack, Box, HStack, Divider, Grid, GridItem } from '@/styled-system/jsx'
import { numberAbbr } from '@/utils'
import { Link } from '@tanstack/react-router'
import { debounce } from '@turbx/utils'
import { useCallback, useEffect, useState, type FC } from 'react'
import { useTranslation } from 'react-i18next'
import { CommunityCard } from './community-card.component'
import { MyCommunityItem } from './my-community-item.component'
import { type Filters, topBarHeight, TopBar } from './top-bar.component'

import checkMark from '../assets/check-mark.png'

const newUserModalId = 'newUser'
export const communityNoPermissionModalId = 'communityNoPermission'

export const CommunityList: FC = () => {
  const { t } = useTranslation()

  const [searchMode, setSearchMode] = useState(false)
  const { data: myCommunitiesDataRaw } = solcloud.useQuery('myCommunities', undefined, { refetchOnMount: 'always' })
  const { tokenAccounts } = useTokenAccounts()
  const myCommunitiesData = myCommunitiesDataRaw?.filter(
    (i) => tokenAccounts?.find((t) => t.token.mint === i.token.mint)?.tokenAccount.permission
  )

  const { data: topTokensDataRaw } = solcloud.useQuery('topTokens', { sort: 'trending' }, { refetchOnMount: 'always' })
  const [filters, setFilters] = useState<Filters>({ exchange: [], index: [] })
  const setFilter = useCallback((type: 'exchange' | 'index', value: string) => {
    setFilters((state) => ({
      ...state,
      [type]: state[type].find((i) => i === value) ? state[type].filter((i) => i !== value) : [...state[type], value]
    }))
  }, [])

  const topTokensData = topTokensDataRaw?.filter((i) => {
    if (filters.exchange.length > 0 && !filters.exchange.includes(i.token.source as 'pumpfun' | 'moonshot'))
      return false
    if (filters.index.length > 0 && (!i.community || !filters.index.includes(i.community.level))) return false

    return true
  })

  const [searchQuery, setSearchQuery] = useState('')
  const { data: tokenSearchData } = solcloud.useQuery('tokenSearch', { query: searchQuery }, { enabled: !!searchQuery })
  const { data: tokenPricesData } = solcloud.useQuery(
    'tokenPrices',
    {
      mints: searchMode
        ? tokenSearchData?.type !== 'not_found'
          ? (tokenSearchData?.data.map((i) => i.token.mint).join('.') ?? '')
          : ''
        : (topTokensData?.map((i) => i.token.mint).join(',') ?? '')
    },
    {
      enabled:
        !!topTokensData ||
        (searchMode && tokenSearchData?.type !== 'not_found' && (tokenSearchData?.data ?? []).length > 0)
    }
  )
  const updateSearchQuery = useCallback(debounce({ delay: 300 }, setSearchQuery), [])

  const isNew = useAuthenticationStore((state) => state.isNew)
  const newUserModal = useModal(newUserModalId)
  const communityNoPermissionModal = useModal(communityNoPermissionModalId)

  useEffect(() => {
    if (isNew) {
      newUserModal.open()
      useAuthenticationStore.getState().setIsNew(false)
    }
  }, [isNew, newUserModal])

  const communityEnter = useCallback(
    (token: SolcloudTypes['MaybeCommunityType']['token']) => async () => {
      router.navigate({ to: '/communities/$mint', params: { mint: token.mint } })
    },
    []
  )

  return (
    <Container
      css={{
        bgColor: 'rgba(242, 242, 242, 1)',
        p: 0,
        h: '100svh',
        overflow: 'auto',
        md: {
          bgColor: '#FFF',
          borderRight: '0.5px solid rgba(0, 0, 0, 0.12)'
        }
      }}>
      <VStack
        css={{
          gap: '16px',
          w: '100%'
        }}>
        <Box
          css={{ w: '10px' }}
          style={{
            height: topBarHeight
          }}
        />
        <TopBar
          searchQuery={searchQuery}
          searchEnabled={searchMode}
          onSearchModeChange={setSearchMode}
          onSearchQueryChange={updateSearchQuery}
          filters={filters}
          setFilter={setFilter}
        />
        {searchMode && tokenSearchData?.type === 'not_found' && (
          <HStack
            css={{
              w: '100%',
              justifyContent: 'center',
              p: '64px',
              color: 'rgba(0, 0, 0, 0.4)',
              fontSize: '14px',
              fontWeight: 400
            }}>
            {t('common.searchMode.message.notFound.content')}
          </HStack>
        )}
        {searchMode &&
          tokenSearchData?.type !== 'not_found' &&
          tokenSearchData?.data.map((item) => {
            const priceItem = tokenPricesData?.find((i) => i.mint === item.token.mint)
            return (
              <HStack
                css={{
                  bgColor: '#FFF',
                  p: '12px 16px',
                  w: '100%',
                  gap: '16px'
                }}
                onClick={communityEnter(item.token)}>
                <Box>
                  <img
                    className={css({
                      w: '64px',
                      h: '64px',
                      rounded: '16px'
                    })}
                    src={item.token.logo ?? ''}
                  />
                </Box>
                <VStack
                  css={{
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    h: '100%',
                    flex: 1,
                    gap: '5.5px'
                  }}>
                  <Box
                    css={{
                      fontWeight: 510,
                      fontSize: '14px',
                      lineHeight: '16.71px'
                    }}>
                    {item.token.name}
                  </Box>
                  <HStack>
                    {priceItem?.priceChange24h && (
                      <PriceIncrease value={Number(Number(priceItem.priceChange24h).toFixed(3))} />
                    )}
                    {item.token.source && <HStack>{item.token.source}</HStack>}
                  </HStack>
                  <HStack
                    css={{
                      minH: '14px',
                      color: 'rgba(144, 67, 0, 0.7)',
                      fontSize: '12px',
                      fontWeight: 400,
                      lineHeight: '14.32px'
                    }}>
                    {priceItem?.marketCap && (
                      <HStack
                        css={{
                          gap: '4px'
                        }}>
                        {t('routes.index.topTokens.marketingCap.label')}{' '}
                        <Box
                          css={{
                            color: 'rgba(144, 67, 0, 1)',
                            fontWeight: 590
                          }}>
                          ${numberAbbr(priceItem.marketCap)}
                        </Box>
                      </HStack>
                    )}
                  </HStack>
                </VStack>
              </HStack>
            )
          })}
        {!searchMode && (
          <VStack
            css={{
              w: '100%',
              alignItems: 'flex-start',
              p: '0'
            }}>
            {(myCommunitiesData ?? []).length > 0 && (
              <HStack css={{ p: '8px 16px', overflow: 'auto', gap: '20px', w: '100%' }}>
                {myCommunitiesData?.map((item) => <MyCommunityItem key={item.token.mint} item={item} />)}
              </HStack>
            )}
            <Divider color="rgba(0, 0, 0, 0.1)" />
            <Grid
              css={{
                w: '100%',
                gap: '16px',
                p: '0 16px',
                gridTemplateColumns: '1fr 1fr',
                md: {
                  gridTemplateColumns: '1fr',
                  gap: '0px',
                  p: 0
                }
              }}>
              {topTokensData?.map((item) => (
                <GridItem key={item.token.mint}>
                  <CommunityCard
                    token={item.token}
                    community={item.community}
                    priceItem={tokenPricesData?.find((i) => i.mint === item.token.mint)}
                  />
                </GridItem>
              ))}
            </Grid>
            <Box style={{ height: footerHeight }} css={{ w: '100%', marginTop: '20px' }} />
          </VStack>
        )}
      </VStack>

      <Modal modalId={newUserModalId}>
        <VStack
          css={{
            bgColor: 'rgba(255, 255, 255, 1)',
            rounded: '16px',
            margin: '0 24px',
            alignItems: 'flex-start',
            p: '24px',
            gap: '24px'
          }}>
          <img
            src={checkMark}
            className={css({
              w: '48px',
              h: '48px'
            })}
          />
          <HStack>{t('routes.index.walletTip.content')}</HStack>
          <HStack css={{ gap: '12px', w: '100%', fontWeight: 590, fontSize: '14px' }}>
            <Link
              to="/wallet"
              className={css({
                flex: 1,
                justifyContent: 'center',
                rounded: '8px',
                h: '48px',
                border: '1px solid rgba(0, 0, 0, 0.08)'
              })}>
              <HStack css={{ h: '100%', w: '100%', justifyContent: 'center' }}>
                {t('routes.index.walletTip.checkWalletButton.label')}
              </HStack>
            </Link>
            <HStack
              css={{
                flex: 1,
                bgColor: 'rgba(129, 22, 195, 1)',
                color: '#FFF',
                h: '48px',
                rounded: '8px',
                justifyContent: 'center'
              }}
              onClick={newUserModal.close}>
              {t('routes.index.walletTip.closeButton.label')}
            </HStack>
          </HStack>
        </VStack>
      </Modal>

      <Modal modalId={communityNoPermissionModalId}>
        <VStack
          css={{
            bgColor: '#FFF',
            gap: '24px',
            p: '24px',
            margin: '0 24px',
            rounded: '16px',
            alignItems: 'flex-start'
          }}>
          <Box
            css={{
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '19.09px'
            }}>
            {(communityNoPermissionModal.payload as { message: string }).message}
          </Box>
          <HStack
            onClick={communityNoPermissionModal.close}
            css={{
              bgColor: 'rgba(129, 22, 195, 1)',
              rounded: '8px',
              h: '48px',
              justifyContent: 'center',
              color: '#FFF',
              fontWeight: 590,
              fontSize: '14px',
              w: '100%'
            }}>
            {t('common.modal.closeButton.label')}
          </HStack>
        </VStack>
      </Modal>
    </Container>
  )
}
