import { type FC } from 'react'
import { useNavbarStore } from './navbar.hook'
import { Icon } from '@turbx/deprecated-design-system'
import { Link } from '@tanstack/react-router'
import { css, type Styles } from '@/styled-system/css'
import { createDataset } from '@/utils'
import { Box, HStack, VStack } from '@/styled-system/jsx'
import { useTranslation } from 'react-i18next'

import logoPurple from '@/assets/logo-purple.png'

export const footerHeight = `58px`
const footerPaddingBottom = `24px`

const iconCss = css({
  w: '24px',
  h: '24px'
})

const navItemCss = css({
  flex: 1,
  md: {
    h: '48px',
    flex: 'unset',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

const navItemInnerCss: Styles = {
  flex: 1,
  gap: '4px',
  fontSize: '12px',
  lineHeight: '14.32px',
  fontWeight: 400,
  color: 'rgba(171, 171, 171, 1)',
  _active: {
    color: '#000'
  }
}

export const Navbar: FC = () => {
  const { t } = useTranslation()
  const visible = useNavbarStore((state) => state.visible)

  if (!visible) return null

  return (
    <Box
      css={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        md: {
          position: 'relative',
          bottom: 'unset',
          left: 'unset',
          right: 'unset',
          borderRight: '0.5px solid rgba(0, 0, 0, 0.12)'
        }
      }}>
      <VStack
        hideBelow="md"
        css={{
          marginTop: '32px',
          marginBottom: '48px'
        }}>
        <img
          className={css({
            w: '32px',
            h: '32px'
          })}
          src={logoPurple}
        />
      </VStack>
      <HStack
        css={{
          w: '100%',
          height: `calc(${footerHeight} + ${footerPaddingBottom})`,
          padding: `8px 16px ${footerPaddingBottom}`,
          bgColor: '#FFF',
          flexDirection: 'row',
          md: {
            flexDirection: 'column',
            w: '64px',
            h: 'unset',
            gap: '4px'
          }
        }}>
        <Link className={navItemCss} to="/">
          {({ isActive }) => (
            <VStack {...createDataset({ 'data-active': isActive })} css={navItemInnerCss}>
              <Icon className={iconCss} name={isActive ? 'home-fill' : 'home-line'} />
              <Box hideFrom="md">{t('common.navbar.home.label')}</Box>
            </VStack>
          )}
        </Link>
        <Link className={navItemCss} to="/ranking">
          {({ isActive }) => (
            <VStack {...createDataset({ 'data-active': isActive })} css={navItemInnerCss}>
              <Icon className={iconCss} name={isActive ? 'bubble-chart-fill' : 'bubble-chart-line'} />
              <Box hideFrom="md">{t('common.navbar.ranking.label')}</Box>
            </VStack>
          )}
        </Link>
        {/* <Link className={navItemCss} to="/">
          {({ isActive }) => (
            <VStack {...createDataset({ 'data-active': isActive })} css={navItemInnerCss}>
              <Icon className={iconCss} name={isActive ? 'coin-fill' : 'coin-line'} />
              {t('common.navbar.trade.label')}
            </VStack>
          )}
        </Link> */}
        <Link className={navItemCss} to="/quest">
          {({ isActive }) => (
            <VStack {...createDataset({ 'data-active': isActive })} css={navItemInnerCss}>
              <Icon className={iconCss} name={isActive ? 'user-fill' : 'user-line'} />
              <Box hideFrom="md">{t('common.navbar.invite.label')}</Box>
            </VStack>
          )}
        </Link>
        <Link className={navItemCss} to="/wallet">
          {({ isActive }) => (
            <VStack {...createDataset({ 'data-active': isActive })} css={navItemInnerCss}>
              <Icon className={iconCss} name={isActive ? 'wallet-fill' : 'wallet-line'} />
              <Box hideFrom="md">{t('common.navbar.wallet.label')}</Box>
            </VStack>
          )}
        </Link>
      </HStack>
    </Box>
  )
}
