import { Outlet, type RouteComponent, createFileRoute } from '@tanstack/react-router'
import { AnimatePresence, Spinner, motion } from '@turbx/deprecated-design-system'
import { SignIn, ForbiddenScreen } from '@/components'
import { useAuthenticationStore, useRefreshTokenEffect, useUserProfile } from '@/stores'
import { css } from '@/styled-system/css'

const signInLayout = css({
  position: 'fixed',
  zIndex: 9,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0
})

const authenticatedLayout = css({
  zIndex: 1
})

const AuthenticatedLayout: RouteComponent = () => {
  useRefreshTokenEffect()
  useUserProfile()

  const authenticated = useAuthenticationStore((state) => !!state.authToken)
  const forbidden = useAuthenticationStore((state) => state.forbidden)

  return (
    <>
      {/* Not authenticated */}
      <AnimatePresence>
        {!authenticated && (
          <motion.div
            layout={true}
            className={signInLayout}
            initial={{ y: '-100%' }}
            animate={{
              y: 0
            }}
            exit={{
              y: '-100%'
            }}
            transition={{
              duration: 0.3,
              bounce: 0.15
            }}>
            <SignIn />
          </motion.div>
        )}
      </AnimatePresence>

      {/* authenticated but loading */}
      <AnimatePresence>
        {authenticated && forbidden === null && (
          <motion.div
            className={css({
              w: '100%',
              h: '100vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            })}
            initial={{ visibility: 'hidden', opacity: 0 }}
            animate={{
              visibility: 'visible',
              opacity: 1
            }}
            exit={{
              visibility: 'hidden',
              opacity: 0
            }}
            transition={{
              duration: 0.1
            }}>
            <Spinner color="rgba(0, 0, 0, 1)" size={24} />
          </motion.div>
        )}
      </AnimatePresence>

      {/* authenticated and allow to visit */}
      <AnimatePresence>
        {authenticated && forbidden === false && (
          <motion.div
            className={authenticatedLayout}
            layout={true}
            initial={{ visibility: 'hidden', opacity: 0 }}
            animate={{
              visibility: 'visible',
              opacity: 1
            }}
            exit={{
              visibility: 'hidden',
              opacity: 0
            }}
            transition={{
              duration: 0.1
            }}>
            <Outlet />
          </motion.div>
        )}
      </AnimatePresence>

      {/* authenticated and not allow to visit */}
      <AnimatePresence>
        {authenticated && forbidden === true && (
          <motion.div
            layout={true}
            className={signInLayout}
            initial={{ y: '-100%' }}
            animate={{
              y: 0
            }}
            exit={{
              y: '-100%'
            }}
            transition={{
              duration: 0.3,
              bounce: 0.15
            }}>
            <ForbiddenScreen />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

export const Route = createFileRoute('/_authenticated')({
  component: AuthenticatedLayout
})
