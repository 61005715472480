import { useAuthenticationStore } from '@/stores'
import { solcloud } from '@/stores/solcloud'
import { useUserProfileQuery } from './useUserProfileQuery'

export const useTokenAccounts = () => {
  const { profile } = useUserProfileQuery()
  const authenticated = useAuthenticationStore((state) => !!state.authToken)
  const { data, isLoading } = solcloud.useQuery(
    'tokenAccounts',
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    { address: profile?.primary_address! },
    {
      enabled: authenticated && !!profile?.primary_address
    }
  )

  return { tokenAccounts: data, isLoading }
}
